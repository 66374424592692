import React from "react";
import Img from "gatsby-image";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import SEO from "../components/seo";
import Layout from "../components/layout";

export default ({ data }) => {
  const entry = data.contentfulExhibition.entry.json;
  const title = data.contentfulExhibition.title;
  const options = documentToReactComponents(entry);

  let imageList = false;
  const images = data.contentfulExhibition.images;
  if (images) {
    imageList = images.map(({ fluid }, index) => (
      <div key={index}>
        <Img fluid={fluid} backgroundColor="#f5f5f5" />
      </div>
    ));
  }

  return (
    <Layout>
      <SEO
        title={title}
        keywords={[
          `Fu-on Chung`,
          `Fu Chung`,
          `Melbourne Artist`,
          `Artist`,
          `Contemporary Artist`,
          `Contemporary Painter`,
          `Painter`
        ]}
      />

      <div className="text-center mb-8">{options}</div>
      {imageList}
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery($title: String!) {
    contentfulExhibition(title: { eq: $title }) {
      title
      entry {
        json
      }
      images {
        id
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
